import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Building, Pencil, Save, X, ArrowLeft, Home, MapPin, User } from 'lucide-react';
import api from '../apiConfig/api';

const Section = ({ children, className = "" }) => (
  <div className={`bg-white rounded-xl shadow-sm ${className}`}>
    {children}
  </div>
);

const InputField = ({ label, value, isEditing, onChange, name, disabled = false }) => (
  <div className="space-y-1.5">
    <label className="text-sm text-gray-600">{label}</label>
    {isEditing ? (
      <input
        type="text"
        value={value || ''}
        onChange={(e) => onChange(name, e.target.value)}
        disabled={disabled}
        className="w-full px-3 py-2 text-sm border border-gray-300 rounded-lg focus:outline-none focus:ring-1 focus:ring-blue-500
          disabled:bg-gray-50 disabled:text-gray-500"
      />
    ) : (
      <div className="px-3 py-2 text-sm bg-gray-50 rounded-lg text-gray-900">
        {value || 'N/A'}
      </div>
    )}
  </div>
);

const Card = ({ title, icon: Icon, children, className }) => (
  <div className={`bg-white rounded-xl shadow-sm ${className}`}>
    <div className="p-4 border-b border-gray-100">
      <div className="flex items-center gap-2">
        <Icon className="h-4 w-4 text-blue-600" />
        <h2 className="text-sm font-medium text-gray-900">{title}</h2>
      </div>
    </div>
    <div className="p-4">
      {children}
    </div>
  </div>
);

const AssetReport = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [isEditing, setIsEditing] = useState(false);
  const [assetData, setAssetData] = useState(null);
  const [originalData, setOriginalData] = useState(null);

  useEffect(() => {
    const fetchAssetDetails = async () => {
      try {
        setIsLoading(true);
        setError(null);
        const response = await api.get(`/api/v1/asset/report/${id}`);
        setAssetData(response.data.data);
        setOriginalData(response.data.data);
      } catch (error) {
        console.error('Error fetching asset details:', error);
        setError('Failed to load asset details');
      } finally {
        setIsLoading(false);
      }
    };

    fetchAssetDetails();
  }, [id]);

  const handleInputChange = (name, value) => {
    setAssetData(prev => ({
      ...prev,
      [name]: value
    }));
  };

  const handleCancel = () => {
    setAssetData(originalData);
    setIsEditing(false);
  };

  const handleSave = async () => {
    try {
      setIsLoading(true);
      await api.put(`/api/v1/asset/report/${id}`, assetData);
      setOriginalData(assetData);
      setIsEditing(false);
    } catch (error) {
      setError('Failed to save changes');
    } finally {
      setIsLoading(false);
    }
  };


  const renderPropertyDetails = () => {
    try {
      if (!assetData?.str_det) return null;
      const details = JSON.parse(assetData.str_det);
      const displayOrder = ['firm_name', 'nature_biz', 'type', 'floors', 'income_range_3'];
      
      return (
        <div className="grid sm:grid-cols-2 lg:grid-cols-3 gap-3">
          {displayOrder.map(key => {
            if (!details[key]) return null;
            return (
              <div key={key} className="bg-blue-50/40 px-3 py-2 rounded-lg">
                <span className="text-sm text-gray-900">{details[key]}</span>
              </div>
            );
          })}
        </div>
      );
    } catch (error) {
      console.error('Error parsing property details:', error);
      return null;
    }
  };

  if (isLoading) {
    return (
      <div className="min-h-screen bg-gray-50 p-4">
        <div className="max-w-7xl mx-auto space-y-4 animate-pulse">
          <div className="h-8 bg-gray-200 rounded w-1/4"></div>
          <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-4">
            {[...Array(6)].map((_, i) => (
              <div key={i} className="bg-white p-4 rounded-xl space-y-3">
                <div className="h-4 bg-gray-200 rounded w-1/4"></div>
                <div className="h-10 bg-gray-200 rounded"></div>
              </div>
            ))}
          </div>
        </div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="min-h-screen bg-gray-50 p-4">
        <div className="max-w-7xl mx-auto">
          <div className="bg-red-50 border-l-4 border-red-500 p-4 rounded">
            <p className="text-red-700">{error}</p>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-gray-50">
      <div className="p-4 lg:p-6">
        {/* Header */}
        <div className="max-w-7xl mx-auto">
          <div className="sticky top-0 z-10 bg-gray-50 pb-4">
            <div className="flex items-center justify-between">
              <div className="flex items-center gap-3">
                <button
                  onClick={() => navigate(-1)}
                  className="p-2 hover:bg-white rounded-lg"
                >
                  <ArrowLeft className="h-5 w-5 text-gray-500" />
                </button>
                <div>
                  <h1 className="text-xl font-semibold text-gray-900">Asset Details</h1>
                  <p className="text-sm text-gray-500 mt-0.5">Ref: {assetData?.asst_ref}</p>
                </div>
              </div>
              <div className="flex gap-2">
                {isEditing ? (
                  <>
                    <button
                      onClick={handleCancel}
                      className="px-3 py-1.5 text-sm text-gray-700 bg-white border border-gray-300 rounded-lg"
                    >
                      Cancel
                    </button>
                    <button
                      onClick={handleSave}
                      className="px-3 py-1.5 text-sm text-white bg-blue-600 rounded-lg flex items-center gap-1.5"
                    >
                      <Save className="h-4 w-4" />
                      Save
                    </button>
                  </>
                ) : (
                  <button
                    onClick={() => setIsEditing(true)}
                    className="px-3 py-1.5 text-sm text-white bg-gray-900 rounded-lg flex items-center gap-1.5"
                  >
                    <Pencil className="h-4 w-4" />
                    Edit
                  </button>
                )}
              </div>
            </div>
          </div>

          {/* Main Content */}
          <div className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-4 lg:gap-6">
            <Card title="Organization Details" icon={Building}>
              <div className="space-y-4">
                <InputField
                  label="Organization"
                  value={assetData?.org_name}
                  isEditing={isEditing}
                  onChange={handleInputChange}
                  name="org_name"
                  disabled={true}
                />
                <div className="grid grid-cols-2 gap-4">
                  <InputField
                    label="Zone"
                    value={assetData?.zone_name}
                    isEditing={isEditing}
                    onChange={handleInputChange}
                    name="zone_name"
                    disabled={true}
                  />
                  <InputField
                    label="Ward"
                    value={assetData?.ward_name}
                    isEditing={isEditing}
                    onChange={handleInputChange}
                    name="ward_name"
                    disabled={true}
                  />
                </div>
              </div>
            </Card>

            <Card title="Owner Details" icon={User}>
              <div className="space-y-4">
                <InputField
                  label="Owner Name"
                  value={assetData?.owner}
                  isEditing={isEditing}
                  onChange={handleInputChange}
                  name="owner"
                />
                <div className="grid grid-cols-2 gap-4">
                  <InputField
                    label="Usage"
                    value={assetData?.usage}
                    isEditing={isEditing}
                    onChange={handleInputChange}
                    name="usage"
                  />
                  <InputField
                    label="EB Number"
                    value={assetData?.eb_num}
                    isEditing={isEditing}
                    onChange={handleInputChange}
                    name="eb_num"
                  />
                </div>
              </div>
            </Card>

            <Card title="Location Details" icon={MapPin}>
              <div className="space-y-4">
                <div className="grid grid-cols-2 gap-4">
                  <InputField
                    label="Door Number"
                    value={assetData?.new_door}
                    isEditing={isEditing}
                    onChange={handleInputChange}
                    name="new_door"
                  />
                  <InputField
                    label="Building Area"
                    value={assetData?.build_area}
                    isEditing={isEditing}
                    onChange={handleInputChange}
                    name="build_area"
                  />
                </div>
                <InputField
                  label="Street Name"
                  value={assetData?.street_name}
                  isEditing={isEditing}
                  onChange={handleInputChange}
                  name="street_name"
                />
              </div>
            </Card>

            <Card 
    title="Property Details" 
    icon={Home} 
    className="md:col-span-2 xl:col-span-1"
  >
    <div className="grid grid-cols-1 gap-3">
      {assetData?.str_det && renderPropertyDetails()}
    </div>
  </Card>
</div>
        </div>
      </div>
    </div>
  );
};

export default AssetReport;