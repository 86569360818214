import React, { useEffect, useState, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { ClipboardCheck, ClipboardList, Calendar, Clock, CheckCircle, Building, ArrowRight, AlertCircle, X, ChevronRight } from 'lucide-react';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, ResponsiveContainer, Tooltip, Cell } from 'recharts';
import { useSurvey } from '../context/surveycontext';
import api from '../apiConfig/api';

const LoadingText = ({ width = "w-40", height = "h-6" }) => (
  <div className={`inline-block ${width} ${height} animate-pulse bg-gradient-to-r from-gray-200 via-gray-300 to-gray-200 rounded relative overflow-hidden`}>
    <div className="absolute inset-0 bg-gradient-to-r from-transparent via-white/20 to-transparent animate-shimmer" />
  </div>
);

const LoadingValue = () => (
  <div className="animate-pulse">
    <div className="h-8 w-16 bg-gray-200 rounded"></div>
  </div>
);

const ErrorMessage = ({ message }) => (
  <div className="flex items-center gap-2 text-red-600 bg-red-50 p-3 rounded-lg">
    <AlertCircle className="h-5 w-5" />
    <span>{message}</span>
  </div>
);

const AssetDetailsModal = ({ isOpen, onClose, assetDetails, isLoading, wardInfo, onAssetClick }) => {
  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black/50 flex items-center justify-center z-50 p-4">
      <div className="bg-white rounded-lg w-full max-w-3xl max-h-[90vh] flex flex-col">
        <div className="p-4 border-b border-gray-200 flex items-center justify-between sticky top-0 bg-white">
          <h2 className="text-lg font-semibold text-gray-800">
            Asset Details - {wardInfo?.ward_name} - Survey Completed
          </h2>
          <button 
            onClick={onClose}
            className="p-1 hover:bg-gray-100 rounded-full transition-colors"
          >
            <X className="h-5 w-5 text-gray-500" />
          </button>
        </div>
        <div className="overflow-auto p-4">
          {isLoading ? (
            <div className="space-y-4">
              {[1, 2, 3].map((i) => (
                <div key={i} className="animate-pulse space-y-2">
                  <div className="h-4 bg-gray-200 rounded w-1/3"></div>
                  <div className="h-4 bg-gray-200 rounded w-1/2"></div>
                </div>
              ))}
            </div>
          ) : assetDetails.length > 0 ? (
            <div className="grid gap-4">
              {assetDetails.map((asset, index) => (
                <div 
                  key={index} 
                  onClick={() => onAssetClick(asset.id)}
                  className="border border-gray-200 rounded-lg p-4 hover:shadow-lg hover:border-gray-300 transition-all cursor-pointer"
                >
                  <div className="grid grid-cols-2 gap-4">
                    <div>
                      <p className="text-sm text-gray-500">Street Name</p>
                      <p className="font-medium">{asset.street_name || 'N/A'}</p>
                    </div>
                    <div>
                      <p className="text-sm text-gray-500">Door No</p>
                      <p className="font-medium">{asset.new_door || 'N/A'}</p>
                    </div>
                    <div>
                      <p className="text-sm text-gray-500">Owner Name</p>
                      <p className="font-medium">{asset.owner || 'N/A'}</p>
                    </div>
                    <div>
                      <p className="text-sm text-gray-500">Usage</p>
                      <p className={`font-medium ${
                        asset.usage?.toLowerCase() === 'commercial' || 
                        asset.usage?.toLowerCase() === 'mixed' 
                          ? 'text-red-600' 
                          : 'text-gray-800'
                      }`}>
                        {asset.usage || 'N/A'}
                      </p>
                    </div>
                  </div>
                  <div className="mt-2 flex justify-end text-sm text-blue-600">
                    View Details <ChevronRight className="h-4 w-4" />
                  </div>
                </div>
              ))}
            </div>
          ) : (
            <div className="text-center py-8 text-gray-500">
              No asset details available
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

const LoadingItem = () => (
  <div className="grid grid-cols-5 gap-4 py-3">
    <div className="col-span-2 space-y-2">
      <div className="h-4 bg-gray-200 rounded w-32"></div>
      <div className="h-3 bg-gray-200 rounded w-24"></div>
    </div>
    <div className="flex justify-center">
      <div className="h-4 bg-gray-200 rounded w-12"></div>
    </div>
    <div className="flex justify-center">
      <div className="h-4 bg-gray-200 rounded w-12"></div>
    </div>
    <div className="flex justify-center">
      <div className="h-4 bg-gray-200 rounded w-12"></div>
    </div>
  </div>
);

const CompletedSurveyHeader = () => (
  <div className="hidden lg:grid grid-cols-5 gap-4 py-3 border-b border-gray-200 bg-gray-50 rounded-t-lg px-4">
    <div className="col-span-2">
      <h4 className="text-sm font-medium text-gray-600">Ward Details</h4>
    </div>
    <div className="text-center">
      <h4 className="text-sm font-medium text-gray-600">Completed</h4>
    </div>
    <div className="text-center">
      <h4 className="text-sm font-medium text-gray-600">Pending</h4>
    </div>
    <div className="text-center">
      <h4 className="text-sm font-medium text-gray-600">Total</h4>
    </div>
  </div>
);

const CompletedSurveyItem = ({ report, onCompletedClick }) => (
  <div className="flex flex-col lg:grid lg:grid-cols-5 gap-4 py-3 border-b border-gray-100 last:border-0 px-4 hover:bg-gray-50">
    <div className="col-span-2">
      <h3 className="font-medium text-gray-800">{report.ward_name}</h3>
      <div className="text-xs text-gray-500 mt-1">
        <p>{report.org_name}</p>
        <p>{report.zone_name}</p>
      </div>
    </div>
    <div className="flex justify-between lg:justify-center items-center">
      <span className="text-sm text-gray-500 lg:hidden">Completed:</span>
      <button
        onClick={() => onCompletedClick(report)}
        className="font-medium text-green-600 hover:text-green-700 transition-colors hover:underline"
      >
        {report.completed || '0'}
      </button>
    </div>
    <div className="flex justify-between lg:justify-center items-center">
      <span className="text-sm text-gray-500 lg:hidden">Pending:</span>
      <span className="font-medium text-blue-600">{report.pending || '0'}</span>
    </div>
    <div className="flex justify-between lg:justify-center items-center">
      <span className="text-sm text-gray-500 lg:hidden">Total:</span>
      <span className="font-medium text-gray-700">{report.total || '0'}</span>
    </div>
  </div>
);

const HomePage = () => {
  const navigate = useNavigate();
  const { setIsSurveyEnabled, setSurveyParams, setZoneName } = useSurvey();
  const dataFetchedRef = useRef(false);

  const [isLoading, setIsLoading] = useState(true);
  const [globalError, setGlobalError] = useState(null);
  const [assetReports, setAssetReports] = useState([]);
  const [surveyDate, setSurveyDate] = useState('');
  const [userData, setUserData] = useState({
    designation: '',
    organizationName: '',
    userId: '',
    orgId: '',
    zoneName: '',
    zoneId: ''
  });
  const [surveyStats, setSurveyStats] = useState({
    totalCompleted: 0,
    totalPending: 0,
    monthlyCount: 0
  });
  const [wardData, setWardData] = useState([]);
  const [dailySurveyData, setDailySurveyData] = useState([]);

  // Modal states
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedWard, setSelectedWard] = useState(null);
  const [assetDetails, setAssetDetails] = useState([]);
  const [isLoadingAssetDetails, setIsLoadingAssetDetails] = useState(false);

  const handleAssetClick = (assetId) => {
    if (!assetId) return;
    navigate(`/report/${assetId}`);
  };

  const handleCompletedClick = async (wardInfo) => {
    try {
      setIsLoadingAssetDetails(true);
      setSelectedWard(wardInfo);
      setIsModalOpen(true);

      const response = await api.get('/api/v1/asset/report', {
        params: {
          org: wardInfo.org_name,
          zone: wardInfo.zone_name,
          ward: wardInfo.ward_name
        }
      });

      console.log('Asset Details Response:', response.data);
      setAssetDetails(response.data.data || []);
    } catch (error) {
      console.error('Error fetching asset details:', error);
      setAssetDetails([]);
    } finally {
      setIsLoadingAssetDetails(false);
    }
  };

  const fetchData = async () => {
    if (dataFetchedRef.current) return;
    dataFetchedRef.current = true;

    try {
      setIsLoading(true);
      setGlobalError(null);

      // 1. Get home data
      const homeDataResponse = await api.get('/api/v1/user/homedata');
      console.log('Home Data Response:', homeDataResponse.data);

      if (!homeDataResponse?.data?.data?.[0]?.created) {
        throw new Error('No survey date available');
      }

      const surveyDate = homeDataResponse.data.data[0].created;
      setSurveyDate(surveyDate);

      // 2. Get user data from localStorage
      const userDataString = localStorage.getItem('userData');
      if (!userDataString) throw new Error('No user data found');

      const parsedUserData = JSON.parse(userDataString);
      const mobileNumber = parsedUserData?.data?.mobile;
      if (!mobileNumber) throw new Error('No mobile number found');

      // 3. Get user login data
      const headers = {
        'Authorization': 'Bearer eyJhbGciOiJIUzI1NiJ9.e30.FXibJVNHsvJ6Ff-N9XtTTom9cGExRqsldHbrhAOqRUg'
      };

      const userResponse = await fetch(
        `https://cmatraining.bahwancybertek.com/CP/api/LoginGIS/Login?Mobileno=${mobileNumber}`,
        { headers }
      );
      const userResponseData = await userResponse.json();
      console.log('User Login Response:', userResponseData);

      if (!userResponseData?.UserID || !userResponseData?.ULBDetails?.[0]?.OrgId) {
        throw new Error('Invalid user data received');
      }

      const userId = userResponseData.UserID;
      const orgId = userResponseData.ULBDetails[0].OrgId;

      // 4. Get zone data
      const zoneResponse = await fetch(
        `https://cmatraining.bahwancybertek.com/CP/api/Master/Drop_GISZoneUserID?OrgID=${orgId}&UserId=${userId}`,
        { headers }
      );
      const zoneData = await zoneResponse.json();
      console.log('Zone Data Response:', zoneData);

      if (!zoneData?.length || !zoneData[0]?.ZoneID) {
        throw new Error('No zone data available');
      }

      const zoneId = zoneData[0].ZoneID;
      const zoneName = zoneData[0].ZoneName;

      // 5. Get ward data
      const wardResponse = await fetch(
        `https://cmatraining.bahwancybertek.com/CP/api/Master/Drop_GISWardUserID?OrgID=${orgId}&UserID=${userId}&ZoneID=${zoneId}`,
        { headers }
      );
      const wardResponseData = await wardResponse.json();
      console.log('Ward Data Response:', wardResponseData);

      if (!wardResponseData?.length) {
        throw new Error('No wards available');
      }

      // 6. Get asset summary using ward IDs
      const wardIds = wardResponseData.map(ward => ward.WardID).join(',');
      const summaryResponse = await api.get(`/api/v1/user/asst_smry?ward_ids=${wardIds}`);
      console.log('Asset Summary Response:', summaryResponse.data);

      const summaryData = summaryResponse.data.data;
      setAssetReports(summaryData);

      // Calculate totals
      const totals = summaryData.reduce((acc, ward) => ({
        totalCompleted: acc.totalCompleted + parseInt(ward.completed || 0),
        totalPending: acc.totalPending + parseInt(ward.pending || 0)
      }), { totalCompleted: 0, totalPending: 0 });

      // Update all states
      setUserData({
        designation: userResponseData.DesignationName || '',
        organizationName: userResponseData.ULBDetails[0].OrgName || '',
        userId,
        orgId,
        zoneName,
        zoneId
      });

      setWardData(wardResponseData);
      setZoneName(zoneName);
      setSurveyStats({
        ...totals,
        monthlyCount: totals.totalCompleted
      });

      setSurveyParams({
        wardData: wardResponseData,
        orgId,
        userId,
        zoneId,
        organizationName: userResponseData.ULBDetails[0].OrgName
      });
      
      setIsSurveyEnabled(true);

    } catch (error) {
      console.error('Error in fetching data:', error);
      setGlobalError(error.message);
      setIsSurveyEnabled(false);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
    return () => {
      dataFetchedRef.current = false;
    };
  }, []);

  const barColors = [
    '#E3F2FD', '#BBDEFB', '#90CAF9', '#64B5F6', '#42A5F5', '#2196F3', '#1E88E5'
  ];

  const getColorClasses = (color) => ({
    blue: { bg: "bg-blue-600", light: "bg-blue-50", text: "text-blue-600" },
    purple: { bg: "bg-purple-600", light: "bg-purple-50", text: "text-purple-600" },
    green: { bg: "bg-green-600", light: "bg-green-50", text: "text-green-600" }
  }[color]);

  const statsData = [
    {
      title: "Completed Surveys",
      value: isLoading ? <LoadingValue /> : surveyStats.totalCompleted.toLocaleString(),
      icon: ClipboardCheck,
      color: "green"
    },
    {
      title: "Pending Surveys",
      value: isLoading ? 
        <LoadingValue /> : 
        `${surveyStats.totalPending.toLocaleString()}/${(surveyStats.totalPending + surveyStats.totalCompleted).toLocaleString()}`,
      icon: ClipboardList,
      color: "purple"
    },
    {
      title: "Monthly Survey Count",
      value: isLoading ? <LoadingValue /> : surveyStats.monthlyCount.toLocaleString(),
      icon: Calendar,
      color: "blue"
    }
  ];

  const handleStartSurvey = () => {
    navigate('/filters', { 
      state: { 
        wardData,
        orgId: userData.orgId,
        userId: userData.userId,
        zoneId: userData.zoneId,
        organizationName: userData.organizationName,
        zoneName: userData.zoneName
      }
    });
  };

  const userName = JSON.parse(localStorage.getItem('userData'))?.data?.name || '';

  return (
    <div className="min-h-screen bg-gray-50">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
        {globalError && (
          <div className="mb-4">
            <ErrorMessage message={globalError} />
          </div>
        )}
        
        <div className="bg-white shadow-xl overflow-hidden">
          <div className="px-6 py-4">
            <h1 className="text-2xl font-bold text-gray-800 mb-1">Welcome {userName}</h1>
          </div>
          
          <div className="bg-gradient-to-r from-[#75d1e3] to-[#66dbf3] px-6 py-4">
            <div className="flex flex-col sm:flex-row justify-between items-center gap-4">
              <div className="flex items-center gap-4">
                <div className="p-2 bg-white/10 rounded-lg backdrop-blur-sm">
                  <Building className="h-5 w-5 text-black" />
                </div>
                <div className="flex flex-col gap-2">
                  <div className="flex items-center">
                    {isLoading ? (
                      <LoadingText width="w-64" height="h-6" />
                    ) : (
                      <h2 className="text-lg text-black">
                        <span className="font-semibold">{userData.organizationName}</span>
                        {userData.zoneName && (
                          <span className="font-normal"> &gt;&gt; {userData.zoneName}</span>
                        )}
                      </h2>
                    )}
                  </div>
                  <div className="flex items-center">
                    {isLoading ? (
                      <LoadingText width="w-32" height="h-4" />
                    ) : (
                      <p className="text-sm text-black/80">
                        {userData.designation || 'No designation available'}
                      </p>
                    )}
                  </div>
                </div>
              </div>
              
              <button 
                onClick={handleStartSurvey}
                disabled={isLoading || !wardData.length}
                className={`flex items-center gap-2 px-4 py-2 bg-white text-[#041013] rounded-lg 
                  transition-all duration-200 font-medium text-sm whitespace-nowrap
                  ${(!isLoading && wardData.length) 
                    ? 'hover:bg-white/90 hover:shadow-md' 
                    : 'opacity-50 cursor-not-allowed'}`}
              >
                {isLoading 
                  ? 'Loading...' 
                  : !userData.zoneName 
                    ? 'No Zone Available' 
                    : !wardData.length 
                      ? 'No Wards Available'
                      : 'Start Survey'}
                <ArrowRight className="h-4 w-4" />
              </button>
            </div>
          </div>
        </div>

        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6 mb-6 pt-10">
          {statsData.map((stat, index) => {
            const colorClasses = getColorClasses(stat.color);
            const Icon = stat.icon;
            return (
              <div key={index} className="bg-white rounded-lg shadow-sm overflow-hidden">
                <div className={`h-1 ${colorClasses.bg}`} />
                <div className="p-6">
                  <div className="flex items-center justify-between">
                    <div className={`p-3 rounded-lg ${colorClasses.light}`}>
                      <Icon className={`h-6 w-6 ${colorClasses.text}`} />
                    </div>
                    <p className="text-2xl font-bold text-gray-800">{stat.value}</p>
                  </div>
                  <p className="mt-4 text-sm font-medium text-gray-600">{stat.title}</p>
                </div>
              </div>
            );
          })}
        </div>

        <div className="grid grid-cols-1 lg:grid-cols-2 gap-6 mb-6">
          <div className="bg-white rounded-lg shadow-sm">
            <div className="p-6">
              <h2 className="text-lg font-semibold text-gray-800 mb-4">Asset detail</h2>
              <div className="rounded-lg border border-gray-200">
                <CompletedSurveyHeader />
                <div className="divide-y divide-gray-200">
                  {isLoading ? (
                    <div className="px-4">
                      {[1, 2, 3].map((i) => (
                        <LoadingItem key={i} />
                      ))}
                    </div>
                  ) : assetReports.length > 0 ? (
                    assetReports.map((report, index) => (
                      <CompletedSurveyItem 
                        key={index} 
                        report={report}
                        onCompletedClick={handleCompletedClick}
                      />
                    ))
                  ) : (
                    <p className="text-gray-500 text-center py-4">No completed surveys found</p>
                  )}
                </div>
              </div>
            </div>
          </div>

          <div className="bg-white rounded-lg shadow-sm p-6">
            <h2 className="text-lg font-semibold text-gray-800 mb-6">Daily Survey Count</h2>
            <div style={{ height: '400px' }}>
              {isLoading ? (
                <div className="h-full flex items-center justify-center">
                  <LoadingItem />
                </div>
              ) : (
                <ResponsiveContainer width="100%" height="100%">
                <BarChart 
                  data={dailySurveyData.length > 0 ? dailySurveyData : assetReports} 
                  margin={{ top: 20, right: 30, left: 20, bottom: 20 }}
                >
                  <CartesianGrid strokeDasharray="3 3" vertical={false} />
                  <XAxis 
                    dataKey="ward_name" 
                    tickSize={6}
                    height={60}
                    tick={{
                      angle: -45,
                      textAnchor: 'end',
                      fontSize: 12
                    }}
                  />
                  <YAxis />
                  <Tooltip
                    contentStyle={{
                      backgroundColor: 'white',
                      border: '1px solid #ccc',
                      borderRadius: '4px',
                      padding: '8px'
                    }}
                  />
                  <Bar dataKey="completed" fill="#8884d8" name="Completed">
                    {(dailySurveyData.length > 0 ? dailySurveyData : assetReports).map((entry, index) => (
                      <Cell 
                        key={`cell-${index}`} 
                        fill={barColors[index % barColors.length]} 
                      />
                    ))}
                  </Bar>
                </BarChart>
              </ResponsiveContainer>
            )}
          </div>
        </div>
      </div>

      <AssetDetailsModal 
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        assetDetails={assetDetails}
        isLoading={isLoadingAssetDetails}
        wardInfo={selectedWard}
        onAssetClick={handleAssetClick}
      />
    </div>
  </div>
);
};

export default HomePage;