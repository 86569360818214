import React, { createContext, useContext, useState } from 'react';

const SurveyContext = createContext();

export const SurveyProvider = ({ children }) => {
  const [isSurveyEnabled, setIsSurveyEnabled] = useState(false);
  const [surveyParams, setSurveyParams] = useState(null);
  const [zoneName, setZoneName] = useState('');

  return (
    <SurveyContext.Provider 
      value={{ 
        isSurveyEnabled, 
        setIsSurveyEnabled, 
        surveyParams, 
        setSurveyParams,
        zoneName,
        setZoneName
      }}
    >
      {children}
    </SurveyContext.Provider>
  );
};

export const useSurvey = () => {
  const context = useContext(SurveyContext);
  if (!context) {
    throw new Error('useSurvey must be used within SurveyProvider');
  }
  return context;
};