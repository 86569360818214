import React, { useState } from 'react';

const ReportDownloadPage = () => {
  const [filter, setFilter] = useState('all');

  const handleFilterChange = (newFilter) => {
    setFilter(newFilter);
  };

  // Updated data structure with variations
  const reports = [
    { 
      id: 1, 
      org: 'Organization X', 
      zone: 'Zone 1', 
      ward: 'Ward A',
      total: 100, 
      pending: 10, 
      completed: 90,
      variationsByUsage: 5,
      variationsByArea: 3,
      variationsByBoth: 2
    },
    { 
      id: 2, 
      org: 'Organization Y', 
      zone: 'Zone 2', 
      ward: 'Ward B',
      total: 80, 
      pending: 40, 
      completed: 40,
      variationsByUsage: 4,
      variationsByArea: 2,
      variationsByBoth: 1
    },
    { 
      id: 3, 
      org: 'Organization Z', 
      zone: 'Zone 3', 
      ward: 'Ward C',
      total: 120, 
      pending: 60, 
      completed: 60,
      variationsByUsage: 8,
      variationsByArea: 5,
      variationsByBoth: 3
    }
  ];

  const handleExportToExcel = () => {
    console.log('Exporting to Excel...');
  };

  const renderTableHeaders = () => {
    switch (filter) {
      case 'Completed':
        return (
          <tr>
            <th className="px-4 py-2 text-left">Organization</th>
            <th className="px-4 py-2 text-left">Zone</th>
            <th className="px-4 py-2 text-left">Ward</th>
            <th className="px-4 py-2 text-right">Total</th>
            <th className="px-4 py-2 text-right">Completed</th>
            <th className="px-4 py-2 text-right">Variations</th>
          </tr>
        );
      case 'Pending':
        return (
          <tr>
            <th className="px-4 py-2 text-left">Organization</th>
            <th className="px-4 py-2 text-left">Zone</th>
            <th className="px-4 py-2 text-left">Ward</th>
            <th className="px-4 py-2 text-right">Total</th>
            <th className="px-4 py-2 text-right">Pending</th>
          </tr>
        );
      case 'Variations':
        return (
          <tr>
            <th className="px-4 py-2 text-left">Organization</th>
            <th className="px-4 py-2 text-left">Zone</th>
            <th className="px-4 py-2 text-left">Ward</th>
            <th className="px-4 py-2 text-right">Total</th>
            <th className="px-4 py-2 text-right">Completed</th>
            <th className="px-4 py-2 text-right">Variations by Usage</th>
            <th className="px-4 py-2 text-right">Variations by Area</th>
            <th className="px-4 py-2 text-right">Variations by Both Area and Usage</th>
          </tr>
        );
      default: // All
        return (
          <tr>
            <th className="px-4 py-2 text-left">Organization</th>
            <th className="px-4 py-2 text-left">Zone</th>
            <th className="px-4 py-2 text-left">Ward</th>
            <th className="px-4 py-2 text-right">Completed</th>
            <th className="px-4 py-2 text-right">Pending</th>
            <th className="px-4 py-2 text-right">Total</th>
          </tr>
        );
    }
  };

  const renderTableRow = (report) => {
    switch (filter) {
      case 'Completed':
        return (
          <tr key={report.id} className="border-b">
            <td className="px-4 py-2">{report.org}</td>
            <td className="px-4 py-2">{report.zone}</td>
            <td className="px-4 py-2">{report.ward}</td>
            <td className="px-4 py-2 text-right">{report.total}</td>
            <td className="px-4 py-2 text-right">{report.completed}</td>
            <td className="px-4 py-2 text-right">
              {report.variationsByUsage + report.variationsByArea}
            </td>
          </tr>
        );
      case 'Pending':
        return (
          <tr key={report.id} className="border-b">
            <td className="px-4 py-2">{report.org}</td>
            <td className="px-4 py-2">{report.zone}</td>
            <td className="px-4 py-2">{report.ward}</td>
            <td className="px-4 py-2 text-right">{report.total}</td>
            <td className="px-4 py-2 text-right">{report.pending}</td>
          </tr>
        );
      case 'Variations':
        return (
          <tr key={report.id} className="border-b">
            <td className="px-4 py-2">{report.org}</td>
            <td className="px-4 py-2">{report.zone}</td>
            <td className="px-4 py-2">{report.ward}</td>
            <td className="px-4 py-2 text-right">{report.total}</td>
            <td className="px-4 py-2 text-right">{report.completed}</td>
            <td className="px-4 py-2 text-right">{report.variationsByUsage}</td>
            <td className="px-4 py-2 text-right">{report.variationsByArea}</td>
            <td className="px-4 py-2 text-right">{report.variationsByBoth}</td>
          </tr>
        );
      default: // All
        return (
          <tr key={report.id} className="border-b">
            <td className="px-4 py-2">{report.org}</td>
            <td className="px-4 py-2">{report.zone}</td>
            <td className="px-4 py-2">{report.ward}</td>
            <td className="px-4 py-2 text-right">{report.completed}</td>
            <td className="px-4 py-2 text-right">{report.pending}</td>
            <td className="px-4 py-2 text-right">{report.total}</td>
          </tr>
        );
    }
  };

  return (
    <div className="container mx-auto px-4 sm:px-6 lg:px-8 py-8">
      <div className="bg-white shadow-md rounded-lg overflow-hidden">
        <div className="flex justify-between items-center px-6 py-4 bg-[#75d1e3] text-black">
          <h2 className="text-lg font-medium">Report Download</h2>
          <button 
            onClick={handleExportToExcel}
            className="flex items-center px-3 py-2 bg-[#75d1e3] text-black rounded-md hover:bg-[#67c3d5] transition-colors"
          >
            <svg className="h-5 w-5 inline-block mr-2" viewBox="0 0 24 24" fill="currentColor">
              <path d="M19 12v7H5v-7H3v7c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2v-7h-2zm-6 .67l2.59-2.58L17 11.5l-5 5-5-5 1.41-1.41L11 12.67V3h2v9.67z" />
            </svg>
            Export to Excel
          </button>
        </div>

        <div className="px-6 py-4">
          <div className="flex justify-center space-x-4 mb-4">
            <button
              className={`px-4 py-2 rounded-md font-medium transition-colors ${
                filter === 'all' ? 'bg-[#75d1e3] text-black' : 'bg-gray-200 text-gray-600 hover:bg-gray-300'
              }`}
              onClick={() => handleFilterChange('all')}
            >
              All
            </button>
            <button
              className={`px-4 py-2 rounded-md font-medium transition-colors ${
                filter === 'Completed' ? 'bg-[#75d1e3] text-black' : 'bg-gray-200 text-gray-600 hover:bg-gray-300'
              }`}
              onClick={() => handleFilterChange('Completed')}
            >
              Completed
            </button>
            <button
              className={`px-4 py-2 rounded-md font-medium transition-colors ${
                filter === 'Pending' ? 'bg-[#75d1e3] text-black' : 'bg-gray-200 text-gray-600 hover:bg-gray-300'
              }`}
              onClick={() => handleFilterChange('Pending')}
            >
              Pending
            </button>
            <button
              className={`px-4 py-2 rounded-md font-medium transition-colors ${
                filter === 'Variations' ? 'bg-[#75d1e3] text-black' : 'bg-gray-200 text-gray-600 hover:bg-gray-300'
              }`}
              onClick={() => handleFilterChange('Variations')}
            >
              Variations
            </button>
          </div>

          <div className="overflow-x-auto">
            <table className="w-full table-auto">
              <thead className="bg-gray-200">
                {renderTableHeaders()}
              </thead>
              <tbody>
                {reports.map(report => renderTableRow(report))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ReportDownloadPage;