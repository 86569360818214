import React, { useState, useEffect } from 'react';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import Header from './components/header';
import LoginPage from './pages/login';
import SurveyForm from './pages/test';
import VerificationPage from './pages/verificationpage';
import HomePage from './pages/homepage';
import { SurveyProvider } from './context/surveycontext';
import './App.css';
import TradeLicenseSection from './pages/tradelisencesection';
import AssetReport from './pages/ReportsPage';
import ReportDownloadPage from './pages/ReportDownload';

function App() {
  const [isLoggedIn, setIsLoggedIn] = useState(() => {
    try {
      return checkTokenValidity();
    } catch {
      return false;
    }
  });
  const [isLoading, setIsLoading] = useState(true);

  const checkTokenValidity = () => {
    try {
      const authToken = localStorage.getItem('authToken');
      const sessionData = JSON.parse(localStorage.getItem('sessionData') || '{}');
      
      if (!authToken || !sessionData.timestamp) {
        handleLogout();
        return false;
      }

      const tokenTimestamp = new Date(sessionData.timestamp);
      const expirationTime = new Date(tokenTimestamp);
      expirationTime.setHours(expirationTime.getHours() + 24 * 7);

      if (new Date() >= expirationTime) {
        handleLogout();
        return false;
      }

      return true;
    } catch (error) {
      console.error('Error checking token validity:', error);
      handleLogout();
      return false;
    }
  };

  const handleLogout = () => {
    localStorage.removeItem('authToken');
    localStorage.removeItem('sessionData');
    localStorage.removeItem('userData');
    localStorage.removeItem('formData');
    localStorage.removeItem('searchResults');
    localStorage.removeItem('surveyFilters');
    localStorage.removeItem('shouldReloadSurvey');
    sessionStorage.removeItem('fromVerification');
    sessionStorage.removeItem('searchMethod');
    sessionStorage.removeItem('pendingStreetId');
    setIsLoggedIn(false);
  };

  const checkAuth = () => {
    const isValid = checkTokenValidity();
    setIsLoggedIn(isValid);
    return isValid;
  };

  useEffect(() => {
    const initializeAuth = async () => {
      try {
        checkAuth();
      } finally {
        setIsLoading(false);
      }
    };

    initializeAuth();

    const tokenCheckInterval = setInterval(() => {
      checkAuth();
    }, 60000);

    const handleStorageChange = (e) => {
      if (e.key === 'authToken' || e.key === 'sessionData') {
        checkAuth();
      }
    };

    window.addEventListener('storage', handleStorageChange);

    return () => {
      clearInterval(tokenCheckInterval);
      window.removeEventListener('storage', handleStorageChange);
    };
  }, []);

  const LoadingSpinner = () => (
    <div className="min-h-screen flex items-center justify-center">
      <div className="relative">
        <div className="animate-spin rounded-full h-16 w-16 border-4 border-gray-200"></div>
        <div className="animate-spin rounded-full h-16 w-16 border-4 border-blue-600 border-t-transparent absolute top-0"></div>
      </div>
    </div>
  );

  const ProtectedRoute = ({ children }) => {
    if (!checkTokenValidity()) {
      return <Navigate to="/login" replace />;
    }

    if (isLoading) {
      return <LoadingSpinner />;
    }

    return children;
  };

  const NotFound = () => (
    <div className="min-h-screen flex items-center justify-center bg-gray-50">
      <div className="max-w-md w-full px-6 py-8 bg-white rounded-lg shadow-lg text-center">
        <h2 className="text-3xl font-bold text-gray-900 mb-2">404</h2>
        <p className="text-xl font-semibold text-gray-800 mb-4">Page Not Found</p>
        <p className="text-gray-600 mb-8">The page you're looking for doesn't exist or has been moved.</p>
        <button
          onClick={() => window.location.href = isLoggedIn ? '/home' : '/login'}
          className="inline-flex items-center px-6 py-3 bg-blue-600 text-white font-medium rounded-lg hover:bg-blue-700 transition-colors duration-200"
        >
          Go Back Home
        </button>
      </div>
    </div>
  );

  if (isLoading) {
    return <LoadingSpinner />;
  }

  return (
    <SurveyProvider>
      <BrowserRouter>
        <div className="min-h-screen bg-gray-50">
          {isLoggedIn && <Header setIsLoggedIn={setIsLoggedIn} />}
          <Routes>
            <Route
              path="/login"
              element={
                isLoggedIn ? (
                  <Navigate to="/home" replace />
                ) : (
                  <LoginPage setIsLoggedIn={setIsLoggedIn} />
                )
              }
            />
            <Route
              path="/filters"
              element={
                <ProtectedRoute>
                  <SurveyForm />
                </ProtectedRoute>
              }
            />
                 <Route
              path="/generatereport"
              element={
                <ProtectedRoute>
                  <ReportDownloadPage/>
                </ProtectedRoute>
              }
            />
             <Route
              path="/report/:id"
              element={
                <ProtectedRoute>
                  <AssetReport/>
                </ProtectedRoute>
              }
            />
            <Route
              path="/verify/:id"
              element={
                <ProtectedRoute>
                  <VerificationPage />
                </ProtectedRoute>
              }
            />
            <Route
              path="/home"
              element={
                <ProtectedRoute>
                  <HomePage />
                </ProtectedRoute>
              }
            />
            <Route
              path="/"
              element={
                <Navigate to={isLoggedIn ? "/home" : "/login"} replace />
              }
            />
            
            <Route path="*" element={<NotFound />} />
          </Routes>
          {isLoading && (
            <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
              <div className="relative">
                <div className="animate-spin rounded-full h-16 w-16 border-4 border-gray-200"></div>
                <div className="animate-spin rounded-full h-16 w-16 border-4 border-blue-600 border-t-transparent absolute top-0"></div>
              </div>
            </div>
          )}
        </div>
      </BrowserRouter>
    </SurveyProvider>

  );
}

export default App;