import React, { useState, useEffect, useRef } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { 
  Search,
  Building, 
  Home,
  ChevronDown,
  AlertCircle,
  LayoutDashboard,
  ClipboardList,
  CheckCircle
} from 'lucide-react';
import { Link } from 'react-router-dom';
import api from '../apiConfig/api';
import TradeLicenseSection from './tradelisencesection';
import { useSurvey } from '../context/surveycontext';

const SelectField = ({ label, id, options, error, icon: Icon, ...props }) => (
  <div className="space-y-2 relative group">
    <label htmlFor={id} className="block text-sm font-semibold text-gray-700 mb-1.5">
      {label}
    </label>
    <div className="relative">
      {Icon && (
        <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
          <Icon className="h-5 w-5 text-[#75d1e3] group-hover:text-[#5dbdd0] transition-colors" />
        </div>
      )}
      <select
        id={id}
        className={`w-full ${Icon ? 'pl-10' : 'pl-4'} pr-10 py-3.5 bg-white border-2 border-gray-200 
        rounded-xl focus:ring-2 focus:ring-[#75d1e3] focus:border-[#75d1e3] transition-all duration-200 
        hover:border-[#75d1e3] shadow-sm appearance-none text-black font-medium 
        disabled:bg-gray-50 disabled:cursor-not-allowed`}
        {...props}
      >
        <option value="" className="text-gray-500">Select {label}</option>
        {options?.map((option, index) => (
          <option 
            key={`${option.value}-${index}`} 
            value={option.value} 
            className="text-black font-medium"
          >
            {option.label}
          </option>
        ))}
      </select>
      <div className="absolute inset-y-0 right-0 flex items-center pr-3 pointer-events-none">
        <ChevronDown className="h-5 w-5 text-gray-400" />
      </div>
    </div>
    {error && (
      <div className="absolute -bottom-6 left-0 flex items-center gap-2 text-red-500 text-sm">
        <AlertCircle size={14} />
        <span className="font-medium">{error}</span>
      </div>
    )}
  </div>
);

const Tab = ({ active, icon: Icon, label, onClick }) => (
  <button
    onClick={onClick}
    className={`flex items-center gap-2 px-6 py-4 font-medium transition-all duration-200 border-b-2 ${
      active 
        ? 'text-[#75d1e3] border-[#75d1e3]' 
        : 'text-gray-500 border-transparent hover:text-[#75d1e3] hover:border-[#75d1e3]'
    }`}
  >
    <Icon className="h-5 w-5" />
    {label}
  </button>
);

const SurveyForm = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { zoneName: contextZoneName } = useSurvey();
  const assessmentInputRef = useRef(null);
  
  const routeParams = {
    orgId: location.state?.orgId,
    userId: location.state?.userId,
    zoneId: location.state?.zoneId,
    organizationName: location.state?.organizationName,
    zoneName: location.state?.zoneName || contextZoneName
  };

  // All state declarations
  const [activeTab, setActiveTab] = useState('pending');
  const [assessmentId, setAssessmentId] = useState('');
  const [assessmentSearchLoading, setAssessmentSearchLoading] = useState(false);
  const [formData, setFormData] = useState({
    ward_id: '',
    street_id: ''
  });
  const [wardStreetSearchLoading, setWardStreetSearchLoading] = useState(false);
  const [formErrors, setFormErrors] = useState({});
  const [userWards, setUserWards] = useState([]);
  const [userStreets, setUserStreets] = useState([]);
  const [searchResults, setSearchResults] = useState([]);
  const [hasSearched, setHasSearched] = useState(false);
  const [orgName, setOrgName] = useState(routeParams.organizationName || '');
  const [streetLoading, setStreetLoading] = useState(false);
  const [streetError, setStreetError] = useState('');
  const [completedData, setCompletedData] = useState([]);
  const [completedDataLoading, setCompletedDataLoading] = useState(false);
  const [searchError, setSearchError] = useState('');
  const [assessmentSearchError, setAssessmentSearchError] = useState('');
  const [shouldAutoSearch, setShouldAutoSearch] = useState(false);
  const [searchMethod, setSearchMethod] = useState(null);
  const [wardsLoading, setWardsLoading] = useState(false);
  const [wardsError, setWardsError] = useState('');
  const [orgCode, setOrgCode] = useState('');
  const [autocompleteResults, setAutocompleteResults] = useState([]);
  const [showAutocomplete, setShowAutocomplete] = useState(false);
  const [autocompleteLoading, setAutocompleteLoading] = useState(false);

  useEffect(() => {
    const getOrgCodeFromStorage = () => {
      try {
        const userData = localStorage.getItem('userData');
        if (userData) {
          const parsedData = JSON.parse(userData);
          const orgCode = parsedData?.data?.resp?.OrgCode || '';
          setOrgCode(orgCode);
          
          if (!assessmentId && orgCode) {
            setAssessmentId(`${orgCode}/`);
          }
        }
      } catch (error) {
        console.error('Error getting org code:', error);
      }
    };

    getOrgCodeFromStorage();
  }, []);

  useEffect(() => {
    const fetchWards = async () => {
      if (!routeParams.orgId || !routeParams.userId || !routeParams.zoneId) {
        setWardsError('Missing required parameters');
        return;
      }

      setWardsLoading(true);
      setWardsError('');

      try {
        const response = await fetch(
          `https://cmatraining.bahwancybertek.com/CP/api/Master/Drop_GISWardUserID?OrgID=${routeParams.orgId}&UserID=${routeParams.userId}&ZoneID=${routeParams.zoneId}`,
          {
            headers: {
              'Authorization': 'Bearer eyJhbGciOiJIUzI1NiJ9.e30.FXibJVNHsvJ6Ff-N9XtTTom9cGExRqsldHbrhAOqRUg'
            }
          }
        );

        if (!response.ok) {
          throw new Error('Failed to fetch ward data');
        }

        const wardData = await response.json();
        
        if (Array.isArray(wardData)) {
          const formattedWards = wardData.map(ward => ({
            value: ward.WardID?.toString() || '',
            label: ward.WardName || 'Unknown Ward'
          }));
          
          setUserWards(formattedWards);
        } else {
          throw new Error('Invalid ward data format');
        }

        const storedFormData = localStorage.getItem('formData');
        if (storedFormData) {
          const parsedFormData = JSON.parse(storedFormData);
          if (parsedFormData?.fromVerification) {
            setFormData({
              ward_id: parsedFormData.ward_id,
              street_id: parsedFormData.street_id
            });
            setHasSearched(true);
            setShouldAutoSearch(true);
          }
        }
      } catch (error) {
        console.error('Error fetching wards:', error);
        setWardsError('Failed to load wards');
        setUserWards([]);
      } finally {
        setWardsLoading(false);
      }
    };

    fetchWards();
  }, [routeParams.orgId, routeParams.userId, routeParams.zoneId]);

  useEffect(() => {
    const autoSearch = async () => {
      if (shouldAutoSearch && formData.ward_id && formData.street_id) {
        await fetchPropertyData();
        setShouldAutoSearch(false);
      }
    };
  
    autoSearch();
  }, [formData.ward_id, formData.street_id, shouldAutoSearch]);

  useEffect(() => {
    const fetchStreets = async () => {
      if (!formData.ward_id) {
        setUserStreets([]);
        return;
      }
  
      setStreetLoading(true);
      setStreetError('');
  
      try {
        const response = await api.get('/api/v1/asset', {
          params: { ward_id: formData.ward_id }
        });
        
        if (response.data && Array.isArray(response.data.data)) {
          const formattedStreets = response.data.data.map(street => ({
            value: street.street_id.toString(),
            label: street.street_name
          }));
          
          setUserStreets(formattedStreets);
  
          if (formData.street_id && shouldAutoSearch) {
            setHasSearched(true);
            await fetchPropertyData();
          }
        } else {
          throw new Error('Invalid street data received');
        }
      } catch (error) {
        console.error('Error fetching streets:', error);
        setStreetError('No Streets found');
        setUserStreets([]);
      } finally {
        setStreetLoading(false);
      }
    };
  
    fetchStreets();
  }, [formData.ward_id]);

  const fetchAutocompleteResults = async (input) => {
    if (input.length < 9) return;
    
    setAutocompleteLoading(true);
    try {
      const response = await api.get(`/api/v1/asset/search`, {
        params: { asst_ref: input }
      });
      
      // The response.data.data is an array of strings (assessment IDs)
      if (response.data?.data && Array.isArray(response.data.data)) {
        // Map the array to match the dropdown format
        const formattedResults = response.data.data;
        setAutocompleteResults(formattedResults);
        setShowAutocomplete(true);
      }
    } catch (error) {
      console.error('Error fetching autocomplete results:', error);
      setAutocompleteResults([]);
    } finally {
      setAutocompleteLoading(false);
    }
  };
  const handleAutocompleteSelection = (selectedId) => {
    setAssessmentId(selectedId);
    setShowAutocomplete(false);
    // Focus back on input after selection
    if (assessmentInputRef.current) {
      assessmentInputRef.current.focus();
    }
  };

  const formatAssessmentId = (value) => {
    const numbers = value.replace(/[^\d]/g, '');
    
    if (numbers.length <= 3) {
      return `${orgCode}/`;
    }
  
    const postOrgCode = numbers.slice(3);
    
    if (postOrgCode.length <= 3) {
      return `${orgCode}/${postOrgCode}`;
    }
    
    if (postOrgCode.length <= 9) {
      return `${orgCode}/${postOrgCode.slice(0, 3)}/${postOrgCode.slice(3)}`;
    }
  
    return `${orgCode}/${postOrgCode.slice(0, 3)}/${postOrgCode.slice(3, 9)}`;
  };

  const handleAssessmentIdChange = (e) => {
    const input = e.target.value;
    
    if (input.length < assessmentId.length) {
      const currentNumbers = assessmentId.replace(/[^\d]/g, '');
      
      if (currentNumbers.length <= 3) {
        return;
      }
      
      const newNumbers = currentNumbers.slice(0, -1);
      const formatted = formatAssessmentId(newNumbers);
      setAssessmentId(formatted);
      
      // Clear autocomplete when backspacing
      setShowAutocomplete(false);
      return;
    }
    
    const formatted = formatAssessmentId(input);
    setAssessmentId(formatted);
    
    // Trigger autocomplete search when length reaches 9 (including slashes)
    if (formatted.length >= 9) {
      fetchAutocompleteResults(formatted);
    }
    
    if (formErrors.assessmentId) {
      setFormErrors((prev) => ({ ...prev, assessmentId: '' }));
    }
  };

  const clearForm = () => {
    setFormData({ ward_id: '', street_id: '' });
    setSearchResults([]);
    setCompletedData([]);
    setHasSearched(false);
    setActiveTab('pending');
    setAssessmentId(orgCode ? `${orgCode}/` : '');
    setAssessmentSearchError('');
    setSearchError('');
    setAutocompleteResults([]);
    setShowAutocomplete(false);
    clearStoredFormData();
    setShouldAutoSearch(false);
    setSearchMethod(null);
  };
  
  const clearStoredFormData = () => {
    localStorage.removeItem('formData');
    sessionStorage.removeItem('fromVerification');
    sessionStorage.removeItem('searchMethod');
    sessionStorage.removeItem('pendingStreetId');
  };

  

  const handleAssessmentSearch = async (e) => {
    e.preventDefault();
    
    const parts = assessmentId.split('/');
    if (!assessmentId.trim() || parts.length !== 3 || 
        parts[0].length !== 3 || parts[1].length !== 3 || parts[2].length !== 6) {
      setFormErrors({ assessmentId: 'Please enter a valid Assessment ID (XXX/XXX/XXXXXX)' });
      return;
    }
  
    setAssessmentSearchLoading(true);
    setAssessmentSearchError('');
    setSearchMethod('assessment');
  
    try {
      const response = await api.get('/api/v1/asset/byref', {
        params: {
          ward_ids: userWards.map(ward => ward.value).join(','),
          asst_ref: assessmentId
        }
      });
  
      if (response.data?.data && response.data.data.id) {
        navigate(`/verify/${response.data.data.id}`);
      } else {
        setAssessmentSearchError('No property details found for this Assessment ID');
      }
  
    } catch (error) {
      console.error('Search error:', error);
      setAssessmentSearchError('No property details found for this Assessment ID');
    } finally {
      setAssessmentSearchLoading(false);
    }
  };

  const handleWardStreetSearch = async (e) => {
    e.preventDefault();
    if (!formData.ward_id || !formData.street_id) {
      setFormErrors({
        ...((!formData.ward_id && { ward_id: 'Ward is required' })),
        ...((!formData.street_id && { street_id: 'Street is required' }))
      });
      return;
    }
    
    setAssessmentSearchError('');
    setFormErrors((prev) => ({ ...prev, assessmentId: '' }));
    setSearchMethod('ward-street');
    
    localStorage.setItem('formData', JSON.stringify({
      ward_id: formData.ward_id,
      street_id: formData.street_id,
      fromVerification: false
    }));
    
    setHasSearched(true);
    await fetchPropertyData();
  };
  
    // Inside your SurveyForm component, replace the existing handleWardChange with this:
    const handleWardChange = (e) => {
      const newWardId = e.target.value;
      setFormData(prev => ({
        ...prev,
        ward_id: newWardId,
        street_id: ''
      }));
      
      setAssessmentSearchError('');
      setFormErrors((prev) => ({ ...prev, ward_id: '', assessmentId: '' }));
      
      // Get selected ward details and update assessment ID
      const selectedWard = userWards.find(ward => ward.value === newWardId);
      if (selectedWard) {
        // Extract numbers from ward name (e.g., "ward-24" or "Ward 24")
        const wardNumber = selectedWard.label.match(/\d+/)?.[0];
        if (wardNumber) {
          // Pad with zeros to make it 3 digits
          const formattedWardNumber = wardNumber.padStart(3, '0');
          const currentParts = assessmentId.split('/');
          if (currentParts.length >= 1) {
            setAssessmentId(`${currentParts[0]}/${formattedWardNumber}/`);
          }
        }
      }
    };
  const handleStreetChange = (e) => {
    setFormData(prev => ({ ...prev, street_id: e.target.value }));
    setAssessmentSearchError('');
    setFormErrors((prev) => ({ ...prev, street_id: '', assessmentId: '' }));
  };

  const handleTabChange = (tab) => {
    setActiveTab(tab);
    if (hasSearched && formData.ward_id && formData.street_id) {
      fetchPropertyData(tab);
    }
  };

  const fetchPropertyData = async (currentTab = activeTab) => {
    const isCompleted = currentTab === 'completed';
    const loadingState = isCompleted ? setCompletedDataLoading : setWardStreetSearchLoading;
    const setData = isCompleted ? setCompletedData : setSearchResults;

    if (!formData.ward_id || !formData.street_id) {
      return;
    }

    loadingState(true);
    setSearchError('');

    try {
      const response = await api.get('/api/v1/asset/', {
        params: {
          ward_id: formData.ward_id,
          street_id: formData.street_id,
          status: isCompleted ? 'Completed' : 'Active'
        }
      });

      if (response.data && Array.isArray(response.data.data)) {
        setData(response.data.data);
      } else {
        throw new Error('Invalid data format received');
      }
    } catch (error) {
      console.error('Error fetching properties:', error);
      setSearchError('No Properties Found');
      setData([]);
    } finally {
      loadingState(false);
    }
  };

  const renderTable = (data) => (
    <table className="w-full">
      <thead>
        <tr className="bg-gray-100 rounded-lg">
          <th className="text-left font-semibold px-6 py-4 border-b w-1/3">Door Number</th>
          <th className="text-left font-semibold px-6 py-4 border-b w-1/3">Owner Name</th>
          <th className="text-left font-semibold px-6 py-4 border-b w-1/3">Total Area (Sq.ft)</th>
        </tr>
      </thead>
      <tbody>
        {data.map((item) => (
          <tr key={item.id} className="border-b hover:bg-gray-50">
            <td className="px-6 py-4 align-middle">
              <Link 
                to={`/verify/${item.id}`}
                className="text-[#75d1e3] hover:text-[#5dbdd0] hover:underline cursor-pointer"
              >
                {item.new_door || item.door_no || 'N/A'}
              </Link>
            </td>
            <td className="px-6 py-4 align-middle">{item.owner || 'N/A'}</td>
            <td className="px-6 py-4 align-middle">
              {item.build_area || item.total_area ? `${item.build_area || item.total_area} sq.ft` : 'N/A'}
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );

  return (
    <div className="min-h-screen bg-gray-50">
      <div className="max-w-7xl mx-auto">
        <div className="bg-white shadow-xl rounded-2xl border border-gray-100">
          <div className="flex flex-col sm:flex-row justify-between items-start sm:items-center border-b border-gray-200">
            <div className="flex items-center gap-3 p-6">
              <div className="p-2 bg-[#75d1e3]/10 rounded-lg">
                <Building className="h-5 w-5 text-[#75d1e3]" />
              </div>
              <div>
                <h3 className="text-lg font-semibold text-gray-800">{orgName}</h3>
              </div>
            </div>
            <div className="flex items-center gap-3 p-6">
              <div className="p-2 bg-[#75d1e3]/10 rounded-lg">
                <LayoutDashboard className="h-5 w-5 text-[#75d1e3]" />
              </div>
              <div>
                <h3 className="text-lg font-semibold text-gray-800">
                  {routeParams.zoneName || 'No Zone'}
                </h3>
              </div>
            </div>
          </div>

          <div className="p-8 space-y-8">
            <div className="flex justify-left gap-4">
              <div className="w-64 relative">
                <label htmlFor="assessment_id" className="block text-sm font-semibold text-gray-700 mb-1.5">
                  Enter Assessment ID
                </label>
                <input
                  ref={assessmentInputRef}
                  type="text"
                  id="assessment_id"
                  value={assessmentId}
                  onChange={handleAssessmentIdChange}
                  className="w-full px-4 py-3.5 border-2 border-gray-200 rounded-xl focus:ring-2 focus:ring-[#75d1e3] focus:border-[#75d1e3] transition-all duration-200 hover:border-[#75d1e3]"
                  placeholder={orgCode ? `${orgCode}/024/111111` : "111/111/111111"}
                />
                
                {showAutocomplete && autocompleteResults.length > 0 && (
  <div className="absolute z-10 w-full mt-1 bg-white border border-gray-200 rounded-xl shadow-lg">
    <ul className="py-1 max-h-60 overflow-auto">
      {autocompleteResults.map((result, index) => (
        <li
          key={index}
          className="px-4 py-2 hover:bg-gray-100 cursor-pointer"
          onClick={() => handleAutocompleteSelection(result)}
        >
          {result}
        </li>
      ))}
    </ul>
  </div>
)}
                {formErrors.assessmentId && (
                  <p className="mt-1 text-red-500 text-sm flex items-center gap-1">
                    <AlertCircle size={14} />
                    {formErrors.assessmentId}
                  </p>
                )}
              </div>
              <div className="w-32 flex items-end">
                <button
                  onClick={handleAssessmentSearch}
                  className="w-full px-6 py-3.5 bg-[#75d1e3] text-white rounded-xl hover:bg-[#5dbdd0] transition-all duration-200 flex items-center justify-center gap-2 shadow-lg hover:shadow-xl font-semibold"
                  disabled={assessmentSearchLoading}
                >
                  {assessmentSearchLoading ? (
                    <div className="animate-spin rounded-full h-5 w-5 border-b-2 border-white"></div>
                  ) : (
                    <Search className="h-5 w-5" />
                  )}
                  Search
                </button>
              </div>
            </div>

            <div className="flex items-center gap-4">
              <div className="flex-1 h-px bg-gray-200"></div>
              <span className="text-gray-500 font-medium">OR</span>
              <div className="flex-1 h-px bg-gray-200"></div>
            </div>

            <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
              <SelectField
                label="Ward Name"
                id="ward_id"
                name="ward_id"
                value={formData.ward_id}
                onChange={handleWardChange}
                options={userWards}
                error={wardsError || formErrors.ward_id}
                icon={Home}
                disabled={wardsLoading}
              />
              <SelectField
                label="Street Name"
                id="street_id"
                name="street_id"
                value={formData.street_id}
                onChange={handleStreetChange}
                options={userStreets}
                error={streetError || formErrors.street_id}
                icon={Home}
                disabled={!formData.ward_id || streetLoading}
              />
              <div className="w-32 flex items-end">
                <button
                  onClick={handleWardStreetSearch}
                  className="w-full px-6 py-3.5 bg-[#75d1e3] text-white rounded-xl hover:bg-[#5dbdd0] transition-all duration-200 flex items-center justify-center gap-2 shadow-lg hover:shadow-xl font-semibold"
                  disabled={wardStreetSearchLoading}
                >
                  {wardStreetSearchLoading ? (
                    <div className="animate-spin rounded-full h-5 w-5 border-b-2 border-white"></div>
                  ) : (
                    <Search className="h-5 w-5" />
                  )}
                  Search
                </button>
              </div>
            </div>
          </div>
        </div>

        {assessmentSearchError && (
          <div className="bg-white shadow-md rounded-lg mt-6 mb-8">
            <div className="p-6 text-center text-red-500 flex items-center justify-center gap-2">
              <AlertCircle size={20} />
              <span className="font-medium">{assessmentSearchError}</span>
            </div>
          </div>
        )}

        {((searchResults.length > 0 || activeTab === 'completed') || searchError) && (
          <div className="bg-white shadow-md rounded-lg mt-6 mb-8">
            <div className="border-b border-gray-200">
              <div className="flex">
                <Tab 
                  active={activeTab === 'pending'} 
                  icon={ClipboardList}
                  label="Pending"
                  onClick={() => handleTabChange('pending')}
                />
                <Tab 
                  active={activeTab === 'completed'} 
                  icon={CheckCircle}
                  label="Completed"
                  onClick={() => handleTabChange('completed')}
                />
              </div>
            </div>
            {searchError ? (
              <div className="p-6 text-center text-red-500">
                {searchError}
              </div>
            ) : (
              <div className="overflow-x-auto">
                <div className="min-w-full p-6">
                  {activeTab === 'pending' ? (
                    wardStreetSearchLoading ? (
                      <div className="flex justify-center items-center py-8">
                        <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-[#75d1e3]"></div>
                      </div>
                    ) : (
                      renderTable(searchResults)
                    )
                  ) : (
                    completedDataLoading ? (
                      <div className="flex justify-center items-center py-8">
                        <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-[#75d1e3]"></div>
                      </div>
                    ) : (
                      renderTable(completedData)
                    )
                  )}
                </div>
              </div>
            )}
          </div>
        )}

        {searchResults.length === 0 && hasSearched && !assessmentSearchLoading && 
         !wardStreetSearchLoading && activeTab === 'pending' && !searchError && (
          <div className="bg-white p-6 text-center rounded-lg shadow-md mt-6 mb-8">
            <p className="text-gray-600">No pending properties found for the selected criteria.</p>
          </div>
        )}

        {completedData.length === 0 && hasSearched && !completedDataLoading && 
         activeTab === 'completed' && !searchError && (
          <div className="bg-white p-6 text-center rounded-lg shadow-md mt-6 mb-8">
            <p className="text-gray-600">No completed properties found for the selected criteria.</p>
          </div>
        )}
      </div>
    </div>
  );
};

export default SurveyForm;